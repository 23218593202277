<template>
  <header class="tw_header" ref='header'>
    <div class="inner">
      <div class="column" style="margin-right: 30px; flex: 1">
        <slot name="title">
          <div>
            <h1 class="title" v-if="title" v-html="title"></h1>
          </div>
        </slot>
        <slot name="column" />
      </div>
      <div class="column">

        <el-dropdown trigger="click" placement="bottom-start" :tabindex="-1" @visible-change="onNoticeVisibleChange">
          <div class="notification">
            <div v-if="unreadCount" class="badge" />
          </div>
          <el-dropdown-menu class="notice_dropdown">
            <tw-notice-list ref="noticeList" />
          </el-dropdown-menu>
        </el-dropdown>

        <div class="flex">
          <el-dropdown trigger="click" placement="bottom-start" @command="onDropDownClick" :tabindex="-1">
            <div class="user_info">
              <figure v-show="user.userIcon || user.userIconDefault" class="avatar">
                <img :src="user.userIcon || user.userIconDefault" v-default-src="'user'">
              </figure>
              <p class="name">{{user.userName}}</p>
            </div>
            <el-dropdown-menu class="header_dropdown">
              <el-dropdown-item command="profile">{{$t('Label.Profile')}}</el-dropdown-item>
              <el-dropdown-item v-if="$route.name === 'Dashboard'" command="editDashBoard">{{$t('Label.Dashboard Edit')}}</el-dropdown-item>
              <el-dropdown-item command="signOut">{{$t('Label.Sign out')}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="company_info" v-if="user[localeLanguage]">
            <div class="company_info_inner">
              <figure class="avatar">
                <img :src="user.companyIcon || '/images/company.svg'">
              </figure>
              <!-- <el-tooltip placement="bottom" popper-class="mcu header_tooltip" :tabindex="-1">
                <div slot="content">
                  <div class="company_name">{{user[localeLanguage].companyName}}</div>
                  <div class="organization_name" v-for="userAssign in user.userAssigns" :key="userAssign.organizationId">
                    {{userAssign[localeLanguage].organizationName}}
                  </div>
                </div>

              </el-tooltip> -->
              <p class="name">{{user[localeLanguage].companyShortName}}</p>
            </div>
            <div class="ids">
              <div>
                <div>Company ID: <span id="guide_company_id">{{user.companyId}}</span></div>
                <div>User ID: <span id="guide_user_id">{{user.userId}}</span></div>
              </div>
              <el-dropdown class="header_dropdown " trigger="click" placement="bottom-end" :tabindex="-1" :hide-on-click="false">
                <div class="el-dropdown-link">
                  <i class="role_chevron" :class="{open: showRoleDropdown}" @click="showRoleDropdown = !showRoleDropdown" />
                </div>
                <el-dropdown-menu class="header_dropdown company_dropdown">
                  <el-dropdown-item>
                    <div class="company_name">
                      <div class="title">Company Name</div>
                      {{user[localeLanguage].companyName}}
                    </div>
                    <div class="organization_name">
                      <div class="title">Organization Name</div>
                        <div v-for="userAssign in user.userAssigns" :key="userAssign.organizationId">
                        {{userAssign[localeLanguage].organizationName}}
                        </div>
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <div class="role">Company Roles<br><span id="guide_company_roles">{{allCompanyRoles}}</span></div>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <div class="role">User Roles<br><span id="guide_user_roles">{{allUserRoles}}</span></div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>

        <!-- <el-select v-if="$route.name === 'Dashboard' && hasBothShipper" size="mini" v-model="role" @change="changeCompanyRole" placeholder="Select">
          <el-option
            v-for="role in companyRoles"
            :key="role.value"
            :label="role.label"
            :value="role.value">
          </el-option>
        </el-select> -->

      </div>
    </div>
    <div v-if="hasBottom" class="inner" :class="{trading_information: tradingInformation}">
      <slot name="bottom" />
    </div>
  </header>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import TwNoticeList from '@/components/molecules/TwNoticeList';
import { LANGUAGE_SETTING, COMPANY_ROLE_CD, COMPANY_ROLE_CD_VARIABLES_OPTIONS, USER_ROLE_CD_VARIABLES_OPTIONS } from 'lib-tw-common';

export default {
  name: 'TwHeader',
  props: {
    title: String,
    hasBottom: Boolean,
    tradingInformation: Boolean,
  },
  components: {
    TwNoticeList,
  },
  data() {
    TwNoticeList
    return {
      role: null,
      companyRoles: [
        {label: 'Export', value: 1},
        {label: 'Import', value: 7},
        // {label: 'Offshore', value: 8}
      ],
      showRoleDropdown: false,
      COMPANY_ROLE_CD_VARIABLES_OPTIONS: COMPANY_ROLE_CD_VARIABLES_OPTIONS,
    }
  },
  computed: {
    user() {
      return this.$store.state.userProfile;
    },
    unreadCount() {
      return this.$store.getters.getUnreadCount;
    },
    hasBothShipper() {
      return this.$store.getters.hasBothShipper;
    },
    // companyRoleCd() {
    //   return this.$store.state.companyRoleCd;
    // },
    localeLanguage() {
      return this.$store.state.language === LANGUAGE_SETTING.ENGLISH ? 'en' : 'local';
    },
    allCompanyRoles() {
      return _.uniq(_.map(this.$store.state.companyRoles, role => _.get(_.find(this.COMPANY_ROLE_CD_VARIABLES_OPTIONS, {code: role}), 'label'))).join(', ');
    },
    allUserRoles() {
      return _.uniq(_.map(_.get(this.$store.state, 'roleInfo.all') || [], role => _.get(_.find(USER_ROLE_CD_VARIABLES_OPTIONS, {code: role.userRoleCd}), 'label'))).join(', ');
    }
  },
  watch: {
    // companyRoleCd(val) {
    //   this.role = val;
    // }
  },
  created() {
    // if (this.companyRoleCd) {
    //   this.role = this.companyRoleCd;
    // }
  },
  mounted() {
    this.observer = new MutationObserver((mutations) => {
      for (let mutation of mutations) {
        if (mutation.type === "childList") this.setHeaderHeight()
      }
    })
    this.observer.observe(this.$refs.header, { childList: true, subtree: true });

    window.addEventListener('resize', this.setHeaderHeight);
  },
  beforeDestroy() {
    this.observer.disconnect();

    window.removeEventListener('resize', this.setHeaderHeight);
  },
  methods: {
    changeCompanyRole() {
      this.$store.commit('SET_COMPANY_ROLE', this.role);
    },
    onDropDownClick(command) {
      if (command === 'signOut') {
        // TODO
        // this.$router.push('/');
        $api.signOut();
      } else if (command === 'profile') {
        this.$router.push({name: 'UserProfile'});
      } else if (command === 'editDashBoard') {
        this.$emit('edit-dashboard');
      }
    },
    onNoticeVisibleChange(visible) {
      if (visible) {
        this.$refs.noticeList.limit = 20;
        this.$refs.noticeList.referenceDate = '';
        this.$refs.noticeList.fetch();
      }
    },
     setHeaderHeight() {
       this.$store.commit('SET_HEADER_HEIGHT', this.$refs.header?.clientHeight);
    }
  }
};
</script>

<style lang="scss" scoped>
  header.tw_header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: $color_white;
    color: $color_black;
    @include drop_shadow;
    z-index: 10;

    .inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 60px 0 58px;
      min-height: 66px;

      .column {
        display: flex;
        align-items: center;
      }
    }

    .inner:first-child {
      min-height: 66px;
      padding-bottom: 2px;
    }

    .inner:not(:first-child) {
      min-height: 46px;
      border-top: 1px solid $color_gray_300;
    }

    &.process .inner:first-child {
      min-height: 77px;
    }

    &.process .inner:not(:first-child) {
      height: 55px;
    }

    h1.title {
      font-weight: bold;
      font-size: 24px;
      line-height: 1.375;
      margin: 0;
      vertical-align: middle;
      word-break: break-all;

      &.middle {
        font-size: 20px;
      }

      &.long {
        font-size: 18px;
      }

      ::v-deep small {
        font-size: 14px;
      }
    }

    .identification {
      font-size: 14px;
      line-height: 20px;
      vertical-align: middle;
      overflow-wrap: anywhere;
    }

    .el-select {
      width: 105px;
      margin-left: 32px;
    }

    div.flex {
      display: flex;
    }
    .user_info,
    .company_info {
      min-height: 40px;
      height: 100%;
      padding-left: 24px;
      display: flex;
      align-items: center;
      figure.avatar {
        width: 32px;
        height: 32px;
        margin-right: 8px;
      }
      .name {
        margin: 0;
        max-width: 180px;
        font-size: 14px;
      }
    }
    .user_info {
      margin-left: 24px;
      margin-right: 24px;
      padding-left: 0;
      cursor: pointer;
    }
    .company_info {
      display: block;
      border-left: 1px solid $color_gray_600;

      .company_info_inner {
        display: flex;
        align-items: center;
      }

      figure.avatar {
        width: 24px;
        height: 24px;
      }

      .name {
        max-width: 120px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .ids {
        display: flex;
        align-items: center;
        margin-top: 2px;
        font-size: 10px;
        line-height: 1.4;
      }
    }

    .notification {
      position: relative;
      width: 28px;
      height: 24.5px;
      background: url(../../assets/images/icons/icon_bell.svg) no-repeat left bottom;
      background-size: 20px auto;
      cursor: pointer;

      .badge {
        position: absolute;
        top: 0;
        right: 0;
        width: 16px;
        height: 15px;
        border-radius: 100%;
        background: #EA5455;
      }
    }

    .inner.trading_information {
      display: block;
      padding: 0;
      // padding: 6px 50px 0 14px;
      height: 55px;
      // overflow-x: auto;
      background: $color_gray_100;

      // &::-webkit-scrollbar {
      //   box-shadow: none;
      // }
    }

    ::v-deep .el-form-item__error {
      position: static;
    }
  }
</style>

<style lang="scss">
  .el-dropdown-menu.el-popper.notice_dropdown {
    padding: 0!important;
    margin-top: 12px;
    z-index: 11!important;
  }
  .el-dropdown-menu.el-popper.header_dropdown {
    z-index: 11!important;

    .el-dropdown-menu__item {
      white-space: nowrap;
    }

    &.users .el-dropdown-menu__item {
      pointer-events: none;
      font-size: 10px;
      line-height: 14px;
      color: $color_gray_800;
      padding: 0 8px 8px;

      &:first-child .user {
        border: none;
      }

      .user {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 18px;
        color: $color_black;
        padding: 8px 0 4px;
        border-top: 1px solid $color_gray_300;
      }
    }
  }

  header.tw_header {
    .tw_customer > span {
      max-width: 240px;
      // overflow: hidden;
      // white-space: nowrap;
      // text-overflow: ellipsis;
    }
  }

  .el-dropdown-menu.el-popper.header_dropdown.company_dropdown {
    max-width: 450px;

    .el-dropdown-menu__item {
      line-height: 1.4;
      font-weight: normal;
      font-size: 14px;
      pointer-events: auto;
      padding: 0 8px 8px;
      white-space: normal;
      cursor: default;

      &:hover, &:focus {
        background-color: transparent;
        color: #3E3A39;
      }
    }

    .company_name {
      margin-bottom: 10px;
      line-height: 1.25;
    }
    .organization_name:not(:last-child) {
      margin-bottom: 4px;
    }

    .company_name, .organization_name {
      .title {
        font-size: 12px;
      }
    }

    .role {
      padding: 8px 0 4px;
      border-top: 1px solid $color_gray_300;
      font-size: 12px;

      span {
        color: $color_gray_600;
      }
    }
  }

  .role_chevron {
    display: block;
    width: 24px;
    height: 24px;
    transform: rotate(0deg);
    background: url(../../assets/images/icons/chevron_down.svg) no-repeat center;
    background-size: 100% auto;
    cursor: pointer;
    transition: transform .3s;
    margin-left: 4px;

    &.open {
      transform: rotate(180deg);
    }
  }
</style>
